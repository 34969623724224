<script
    lang="ts"
    setup
>
    type Props = {
        direction?: 'up' | 'right' | 'down' | 'left'
        width?: number | string
        offsetY?: number | string
        offsetX?: number | string
        tailOffsetY?: number | string
        tailOffsetX?: number | string
        disabled?: boolean
        disableClosing?: boolean
        pointerEvents?: string
        closeOnClickOutside?: boolean
        noWrap?: boolean
    }

    type Emit = {
        (event: 'opened' | 'closed'): void
    }

    const INDENT = 16

    const props = withDefaults(defineProps<Props>(), {
        direction: 'up',
        width: undefined,
        offsetY: 0,
        offsetX: 0,
        tailOffsetY: 0,
        tailOffsetX: 0,
        disabled: false,
        disableClosing: false,
        pointerEvents: 'none',
        closeOnClickOutside: false,
        noWrap: false,
    })
    const emit = defineEmits<Emit>()

    const tooltipRef = ref<ReturnType<typeof defineComponent>>()

    const active = ref<boolean>(false)
    const closeOnBlur = ref<boolean>(false)

    if (props.closeOnClickOutside) {
        onClickOutside(tooltipRef, () => (active.value = false))
    }

    const style = useCssModule()

    const tooltipStyle = computed<string>(() => {
        let style = ''

        if (props.width) {
            style += `--tooltip-width: ${ getSizeValue(props.width) };`
        }

        if (props.tailOffsetY) {
            style += `--tooltip-tail-offset-y: ${ getSizeValue(props.tailOffsetY) };`
        }

        if (props.tailOffsetX) {
            style += `--tooltip-tail-offset-x: ${ getSizeValue(props.tailOffsetX) };`
        }

        return style || undefined
    })
    const tooltipClass = computed<string[]>(() => [
        style['tooltip'],
        style['tooltip--' + props.direction],
        props.noWrap ? 'whitespace-nowrap' : undefined,
    ].filter(Boolean))
    const offsets = computed(() => ({
        x: parseInt(props.offsetX as string) || 0,
        y: parseInt(props.offsetY as string) || 0,
    }))

    const open = (): void => {
        if (props.disabled) {
            return
        }

        active.value = true

        setTimeout(() => emit('opened'), 0)
    }

    const close = (event = undefined): void => {
        if (props.disableClosing) {
            return
        }

        if ('tooltip' in (event?.relatedTarget?.dataset || {})) {
            closeOnBlur.value = true

            return
        }

        active.value = false

        setTimeout(() => emit('closed'), 0)
    }

    const toggle = (event): void => active.value ? close(event) : open()

    const tooltipEnter = (el, done): void => {
        const { top, left, width, height } = el.previousElementSibling.getBoundingClientRect()

        let topValue: number
        let leftValue: number

        switch (props.direction) {
            case 'up':
                topValue = top - el.clientHeight - INDENT
                leftValue = (left + (width / 2)) - (el.clientWidth / 2)
                break
            case 'right':
                topValue = (top + (height / 2)) - (el.clientHeight / 2)
                leftValue = left + width + INDENT
                break
            case 'down':
                topValue = top + height + INDENT
                leftValue = (left + (width / 2)) - (el.clientWidth / 2)
                break
            case 'left':
                topValue = (top + (height / 2)) - (el.clientHeight / 2)
                leftValue = left - el.clientWidth - INDENT
        }

        el.style.top = topValue + offsets.value.y + 'px'
        el.style.left = leftValue + offsets.value.x + 'px'

        done()
    }

    defineExpose({
        active,
        open,
        close,
        toggle,
    })
</script>

<template>
    <slot
        name="activator"
        :active="active"
        :open="open"
        :close="close"
        :toggle="toggle"
    />

    <Transition
        :css="false"
        @enter="tooltipEnter"
    >
        <div
            v-if="active"
            key="tooltip"
            ref="tooltipRef"
            data-tooltip=""
            :class="tooltipClass"
            :style="tooltipStyle"
            @mouseleave.passive="closeOnBlur && close()"
        >
            <slot
                :active="active"
                :close="close"
            />
        </div>
    </Transition>
</template>

<style
    lang="sass"
    module
    scoped
>
    .tooltip
        pointer-events: v-bind('props.pointerEvents')
        z-index: 10
        position: fixed
        display: flex
        flex-direction: column
        width: var(--tooltip-width, auto)
        padding: 12px 16px
        background: #000
        color: #fff
        border-radius: 8px

        &::before
            content: ''
            position: absolute
            left: calc(50% - var(--tooltip-tail-offset-x, 10px))
            border-right: 10px solid transparent
            border-left: 10px solid transparent
            transform: translateY(-50%)

        &::after
            content: ''
            position: absolute
            width: 100%
            height: 20px
            transform: translateY(-50%)

        &--up::before
            top: calc(100% + 7px)
            border-top: 10px solid #000
            border-bottom: 10px solid transparent

        &--right::before
            top: calc(50% - var(--tooltip-tail-offset-y, 0px))
            right: calc(100% - 3px)
            left: auto
            border-top: 10px solid transparent
            border-bottom: 10px solid transparent
            border-right: 10px solid #000

        &--down::before
            top: -7px
            border-top: 10px solid transparent
            border-bottom: 10px solid #000

        &--left::before
            top: 50%
            right: auto
            left: calc(100% - 3px)
            border-top: 10px solid transparent
            border-bottom: 10px solid transparent
            border-left: 10px solid #000
</style>
