import type { AnyFn, MaybeRef } from '~/ts/types/common'

type Options = {
    ignore?: (Ref | HTMLElement | string)[]
    capture?: boolean
}

export const onClickOutside = (target: MaybeRef<HTMLElement>, handler: AnyFn, options: Options = {}): void => {
    const { window, document } = getClientContext()

    const {
        ignore = [],
        capture = true,
    } = options

    let shouldListen = true

    const compareEl = (el, event): boolean => (el === event.target || event.composedPath().includes(el))

    const shouldIgnore = (event: PointerEvent) => {
        return ignore.some((targetToIgnore) => {
            if (typeof targetToIgnore === 'string') {
                return Array.from(document.querySelectorAll(targetToIgnore)).some(el => compareEl(el, event))
            } else {
                const el = unrefElement(targetToIgnore)

                return el && compareEl(el, event)
            }
        })
    }

    const listener = (event: PointerEvent) => {
        const el = unrefElement(target)

        if (!el || compareEl(el, event)) {
            return
        }

        if (event.detail === 0) {
            shouldListen = !shouldIgnore(event)
        }

        if (!shouldListen) {
            shouldListen = true

            return
        }

        handler(event)
    }

    onEvent(window, 'click', listener, { passive: true, capture })

    onEvent(window, 'pointerdown', (event: PointerEvent) => {
        const el = unrefElement(target)

        if (el) {
            shouldListen = !event.composedPath().includes(el) && !shouldIgnore(event)
        }
    }, { passive: true })
}
