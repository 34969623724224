<script
    lang="ts"
    setup
>
    type Props = {
        file: string
    }

    const props = defineProps<Props>()
</script>

<template>
    <img
        :src="`/img/${ props.file }.png`"
        :srcset="`/img/${ props.file }@2x.png 2x`"
        :alt="props.file"
        draggable="false"
    />
</template>
